import React from "react"
import ReactDOM from "react-dom"

import App from "./app/App"

type ServerProps = {
  domain: string
}

ReactDOM.render(
  <React.StrictMode>
    <App {...window.appServerProps as ServerProps} />
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
)
